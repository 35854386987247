/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, {useRef, Component, createRef } from 'react'
import { GiAutoRepair } from "react-icons/gi";
import { FaLaptopCode } from "react-icons/fa";
import { MdMobileFriendly } from "react-icons/md";
import { InView } from 'react-intersection-observer'
import 'semantic-ui-css/semantic.min.css'
import { useForm } from 'react-hook-form'
import emailjs from '@emailjs/browser'
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react'
import { Link, animateScroll as scroll } from 'react-scroll'
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';

import 'react-notifications-component/dist/theme.css'

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

class DesktopContainer extends Component {
  state = {}

  toggleFixedMenu = (inView) => this.setState({ fixed: !inView })
  contextRef  = createRef()

  render() {
    const { children } = this.props
	
    return (
	<Media greaterThan='mobile'>
		<InView onChange={this.toggleFixedMenu} className={'sticky whiteBg'}>
			<Grid className={'divide-x-2'}>
				<Grid.Column width={2}>
					<Container className='pl-4 pt-4 pb-4'>
						<img className='headerImg' src={require('./ApogeeLogoBlack.png')} alt='logo'/>		  
					</Container>
				</Grid.Column>			
				<Grid.Column className='alignCenter'>
					<Menu className='borderless'>
						<Menu.Item 
							position='right' 
							 
							onClick={scroll.scrollToTop}
							content='Home'
							active
						>
						</Menu.Item>
						<Menu.Item>
							<Link
							to='services'
							spy={true}
							smooth={true}
							offset={-90}
							className='fontBlack'
							>
								Services
							</Link>
						</Menu.Item>
						<Menu.Item>
							<Link
							to='about'
							spy={true}
							smooth={true}
							offset={-50}
							className='fontBlack'
							>				
								About Us
							</Link>
						</Menu.Item>		
					</Menu>
				</Grid.Column>	
			</Grid>
		</InView>
	{children}
	</Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
			style={{maxHeight: 160}}
          >
            <Menu.Item 
			as={ Link }
			to='navMenu'
			spy={true}
			smooth={true}			
			onClick={this.handleSidebarHide}
			active
			>
              Home
            </Menu.Item>
			<Menu.Item 
			as={ Link }
			to='services'
			spy={true}
			smooth={true}			
			onClick={this.handleSidebarHide}
			>
				Services
			</Menu.Item>
			<Menu.Item
			as={ Link }
			to='about'
			spy={true}
			smooth={true}			
			onClick={this.handleSidebarHide}
			>
				About Us
			</Menu.Item>
            <Menu.Item
			as={ Link }
			to='contact'
			offset={-70}
			spy={true}
			smooth={true}			
			onClick={this.handleSidebarHide}
			>
				Contact Us
			</Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 50, padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                  <Menu.Item position='right'>
                    <Button >
						<Link
						to='contact'
						spy={true}
						smooth={true}
						offset={-70}
						className='fontBlack'
						>				
							Contact Us
						</Link>
                    </Button>
                  </Menu.Item>
                </Menu>
              </Container>
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const ContactForm = () => {
  const { register, handleSubmit } = useForm();
  const form = useRef();
  const onSubmit = (data) => {

	scroll.scrollToTop()
    emailjs.sendForm('service_jb17hw5', 'template_ub2tixe', form.current, 'EpvE8F4ObvCeHA5z5')
      .then((result) => {
		Store.addNotification({
			title: "THANK YOU!",
			message: "Your message has been sent!",
			type: "success",
			insert: "top",
			container: "top-full",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
			  duration: 2000,
			  onScreen: true
			}
		  });
      }, (error) => {
		  console.log("Error");
          console.log(error.text);
      });
  };

  return (
    <div id='contact' className="App">
		<Grid>
			<Grid.Row>
				<Grid.Column width={10} textAlign='left'>
					<form ref={form} onSubmit={handleSubmit(onSubmit)} className='noMarginLeft'>
						<div>
							<label htmlFor="name">First Name</label>
								<input 
								placeholder="Enter your name" 
								type="text"
								{...register("name")} 
								/>
						</div>
						<div>
							<label htmlFor="email">Email</label>
								<input
								placeholder="Enter your email"
								type="email"
								{...register("email")}
								/>
						</div>
						<div>
							<label htmlFor="message">Message</label>
								<input 
								placeholder="Enter your message" 
								type="text"
								{...register("message")} 
							/>
						</div>
						<Media greaterThan='mobile'>
							<input 
							type="submit" 
							value="Submit Message"
							/>
						</Media>
						<Media lessThan='tablet'>
							<input 
							type="submit" 
							value="Submit"
							//On Click go to top of page
							/>					
						</Media>						
					</form>				
				</Grid.Column>
			</Grid.Row>
		</Grid>
    </div>
  );
}

  
const App = () => (
  <ResponsiveContainer>
	<ReactNotifications />
    <Segment style={{ padding: '8em 0em' }} vertical inverted>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={10}>
            <Header as='h2' style={{ color:'white', fontSize: '3em' }}>
              Control your development
            </Header>
            <p style={{ fontSize: '1.33em' }}>
				With a customer-centric approach at the core of our philosophy, we collaborate closely with our clients to understand 
				their goals, challenges, and aspirations. We develop custom software
				solutions that are meticulously tailored to your requirements, ensuring seamless integration and maximum impact.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='left'>
            <Button size='huge'>
				<Link
				to='contact'
				spy={true}
				smooth={true}
				offset={-70}
				className='fontBlack'
				>				
					Contact Us
				</Link>
			</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
	<Segment>
		<div id='services' className='ui three column doubling stackable grid centerText paddingTopOverride paddingBottomOverride'>
			<div className='column paddingLeftOverride'>
				<div className='ui fluid card h-80'>
					<div className='pt-8'>
						<h2> Web and App Development </h2>				
						<Icon className='cardIcon'> <MdMobileFriendly/> </Icon>
						<p> Our website and app development services can range anywhere from a single view landing page to a larger
						customer facing website that requires custom API's.  </p>
					</div>
				</div>
			</div>			
			<div className='column'>
				<div className='ui fluid card h-80'>
					<div className='pt-8'>
						<h2> Desktop Software Solutions </h2>				
						<Icon className='cardIcon'> <FaLaptopCode/> </Icon>
						<p> We develop new desktop applications using Python and the .NET platform and can upgrade existing apps. From simple user interfaces
						to more complex MVC frameworks we can help. </p>
					</div>
				</div>
			</div>				
			<div className='column paddingRightOverride'>
				<div className='ui fluid card h-80'>
					<div className='pt-8'>
						<h2> Software Modernizing </h2>				
						<Icon className='cardIcon'> <GiAutoRepair/> </Icon>
						<p> We can assist with refactoring and optimizing current legacy software to avoid the need for finding new products
						and solutions. </p>
					</div>
				</div>
			</div>		
		</div>
	</Segment>
    <Segment id='about' className="borderLess" style={{ padding: '0em' }} vertical>
      <Grid container stackable verticalAlign='middle' >
        <Grid.Row textAlign='left'>
          <Grid.Column width={10} className="noBoxShadow" style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '3em' }}>
              About Apogee Software
            </Header>
            <p style={{ fontSize: '1.33em' }}>
				At Apogee Software, we are passionate about crafting innovative software solutions that empower 
				businesses and individuals to thrive in the digital world. Our small but dedicated team of talented 
				developers, designers, and problem-solvers work tirelessly to deliver high-quality, 
				customized software applications that meet the unique needs of our clients.
			</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '8em 0em' }} vertical>
      <Container>
		<Header as='h3' style={{ fontSize: '3em' }}>
			Contact us
		</Header>
		<ContactForm />
      </Container>
    </Segment>

    <Segment inverted vertical style={{ padding: '5em 0em' }}>
		<Container>
			<Grid divided inverted stackable>
				<Grid.Row>
					<Grid.Column width={3}>
						<Header as='h4'>
							<Link 
							to='contact'
							spy={true}
							smooth={true}
							offset={-191}
							className='fontWhite'
							>
							Contact
							</Link>
						</Header>
					</Grid.Column>
					<Grid.Column width={3}>
						<Header as='h4'>
							<Link 
							to='services'
							spy={true}
							smooth={true}
							offset={-121}
							className='fontWhite'
							>
							Services
							</Link>
						</Header>
					</Grid.Column>
					<Grid.Column width={7}>
						<Header as='h4' inverted>
							©2024 Apogee Software LLC All rights reserved.
						</Header>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
    </Segment>
  </ResponsiveContainer>
)

export default App
